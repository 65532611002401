<template>
  <v-app id="login">
    <v-layout align-center justify-center row fill-height style="background-color: white;">
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-1 pa-3">
          <v-card-text>
            <div class="layout column align-center pa-3">
              <img :src="require('./../assets/logo-safir.png')" class="logo" />
            </div>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="user.email"
                :rules="emailRules"
                prepend-icon="mail"
                name="email"
                label="E-mail"
                type="text"
                required
                outline
                autofocus
                browser-autocomplete="on"
              ></v-text-field>
              <v-text-field
                v-model="user.senha"
                :rules="campoRules"
                id="senha"
                prepend-icon="lock"
                name="senha"
                label="Senha"
                type="password"
                required
                @keyup.enter="login(user)"
                outline
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn block flat @click="senhaEmail()" color="primary">Esqueci minha senha</v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn color="primary" block round large :disabled="!valid" @click="login(user)">ENTRAR</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <!-- <v-flex sm12 md8 style="background-color: #006d7c; height: 100%" class="hidden-sm-and-down"> -->
      <v-flex
        xs12
        sm8
        md8
        style="background-color: #006d7c; height: 100%"
        class="hidden-sm-and-down"
      >
        <v-card class="elevation-1 pa-3" style="height: 100%; background-color: #006d7c;">
          <v-card-text>
            <div class="layout column align-center">
              <img
                :src="require('./../assets/undraw_Finance_re_gnv2.svg')"
                height="600"
                width="600"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
const usuarioModule =()=>import('./../services/usuario')

export default {
  name: 'Auth',
  data () {
    return {
      valid: true,
      user: {
        email: null,
        senha: null
      },
      emailRules: [
        v => !!v || 'E-mail é obrigatório.',
        v => /.+@.+/.test(v) || 'E-mail não é válido.'
      ],
      campoRules: [
        v => !!v || 'Campo é obrigatório.',
      ],
    }
  },
  mounted () {
    this.logout()
  },
  methods: {
    ...mapActions(['attempLogin', 'logout']),
    async login (user) {
      try {
        // VALIDA FORMULARIO
        if (!this.$refs.form.validate()) {
          return
        }
        // BUSCA USUARIO NO BANCO ATRAVES DAS CREDENCIAIS
        user.axios = this.$axios
        let resp = await this.attempLogin(user)
        if (resp.status === 200 && resp.data) {
          this.$router.push('/dashboard')

        } else if (resp.response.status === 401) {

          this.$swal('Senha inválida', '', 'error')

        } else if (resp.response.status === 400) {
          this.$swal('Usuário não encontrado', '', 'error')
        }

      } catch (error) {
        this.$swal('Estamos com problemas tecnicos, tente novamente mais tarde.', '', 'error')
      }
    },
    async senhaEmail () {
      try {
        let email = await this.$swal({
          title: 'Informe seu E-mail',
          input: 'email',
          inputValue: this.user.email
        })
        if (email.value === '') {
          this.$swal('Por favor preencha todos o(s) campo(s).', '', 'error')
          return
        }
        let serviceUser = await usuarioModule()
        let resp = await serviceUser.resetPasswordByEmail(this.$axios, {
          email: email.value
        })
        this.$swal(resp.data.msg, '', resp.data.error ? 'error' : 'success')
      } catch (error) {
        console.log(error)
      }
    },
  }
}

</script>

<style scoped lang="css">
* {
  overflow: hidden;
}
</style>